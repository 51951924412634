<template>
  <div class="how-section">
    <ImageModal
      :showModal="isModalVisible"
      :imageSrc="modalImageSrc"
      :imageAlt="modalImageAlt"
      :imageDescription="modalImageDescription"
      @close="closeModal"
    />

    <h2 class="header">Do's and Don'ts</h2>
    <div class="content">
      <div class="column dos">
        <h3>DOs</h3>
        <ul>
          <li>Full frontal pics</li>
          <li>
            No makeup, yup bring on the dark undereyes, the skin unevenness, the
            age spots, the blemishes etc. Show that life experience on your face
            proudly!
          </li>
          <li>Early morning, or afternoon natural lighting</li>
          <li>
            Grab a stack of white printer paper and put it in front of your face
            sideways covering up any top you're wearing.
          </li>
        </ul>
      </div>
      <div class="column donts">
        <h3>DON'Ts</h3>
        <ul>
          <li>Overhead noon lighting 🌞</li>
          <li>Bright lights 💥</li>
          <li>Low lights 💡</li>
          <li>Party lights</li>
          <li>
            No lights/ Complete darkness 🌚, candlelight 🕯️ or flashlights 🔦,
            we're not telling scary stories, I promise!
          </li>
          <li>
            Squinty face/ closed eyes, or winky faces 😉- we want to see those
            pretty peepers of yours
          </li>
          <li>
            Busy backgrounds, or backgrounds too close in color to your hair
            color. We just don't want you blending into the wall (Ideal is
            white, but who has a white wall readily available? lol So let's work
            with what we got)
          </li>
          <li>
            3/4 views, side views, upside down views, political views, etc. 🙃
          </li>
        </ul>
      </div>
    </div>
    <br>
    <div style="text-align: center;">
      <h2>Samples</h2>
    </div>
    <br>
    <div class="image-carousel">
      <div v-for="(truncatedDescription, index) in truncatedDescriptions" :key="index">
        <img :src="images[index].src" :alt="images[index].alt" @click="expandImage(images[index])" />
        <p class="image-description">{{ truncatedDescription }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import ImageModal from "./ImageModal.vue";

export default {
  name: "HowSection",
  components: {
    ImageModal,
  },
  data() {
    return {
      isModalVisible: false,
      modalImageSrc: "",
      modalImageAlt: "",
      modalImageDescription: "",
      images: [
        {
          src: require("@/assets/imageOne.png"), // Assuming '@/assets' is the correct path
          alt: "Image 1",
          description: "Full face: Hair back, (bangs off of the forehead if you have them)- Tip: Headbands help!",
        },
        {
          src: require("@/assets/imageTwo.jpg"), // Assuming '@/assets' is the correct path
          alt: "Image 2",
          description: "Full face: Hair down",
        },
        {
          src: require("@/assets/imageThree.jpg"), // Assuming '@/assets' is the correct path
          alt: "Image 3",
          description: "Eyeball pic clear enough to see the 'flecks' in your iris if you have them. If you don't, still clear enough to see the colors when I zoom in reeeeeally close and stare into your soul. Front phone cameras usually suck at this, so the back camera has a better chance, especially if we wipe it clean thoroughly. Bonus points if you pull a pro-photographer move and use a digital camera. If you can't, let me know your best observation.)",
        },
        {
          src: require("@/assets/imageFour.jpg"), // Assuming '@/assets' is the correct path
          alt: "Image 4",
          description: "Inside of the forearm (I'm not looking for wrist veins- we all know that's too confusing to work, right?! I'm looking for a patch of skin that generally doesn't get redness/ inflammation, as that can skew us into thinking we're automatically cool, particularly if we spent time in the sun, have rosacea, have run a mile before breakfast etc. Pinkness can exist on all skin tone colors, but it's not necessarily the skin color's temperature.)",
        },
        {
          src: require("@/assets/imageFive.jpg"), // Assuming '@/assets' is the correct path
          alt: "Image 5",
          description: "Funny face, because fashion is not THAT serious! ;) Fashion is here to bring us joy :)))) 💃",
        }
      ],
    };
  },
  computed: {
    truncatedDescriptions() {
      return this.images.map(image => {
        let maxChars = 100;
        if (image.description.length > maxChars) {
          return image.description.substring(0, maxChars) + '...';
        } else {
          return image.description;
        }
      });
    }
  },
  methods: {
    expandImage(image) {
      this.modalImageSrc = image.src;
      this.modalImageAlt = image.alt;
      this.modalImageDescription = image.description;
      this.isModalVisible = true;
    },

    closeModal() {
      this.isModalVisible = false;
    },
  },
};
</script>

<style scoped>
.how-section {
  text-align: center;
  padding: 20px;
  padding-bottom: 20px; /* Increased bottom padding for more height */
  background-color: #ecebeb;
  /* min-height: 300px;  */
}

.header {
  font-size: 38px;
  margin-bottom: 20px;
}

.content {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.column {
  width: 45%;
}

.column h3 {
  font-size: 28px; /* Increase the font size for h3 */
  text-align: left;
  margin-bottom: 10px;
}

.column ul {
  font-size: 18px; /* Increase the font size for ordered list */
  text-align: left;
  padding-left: 20px; /* Adjust padding to align text properly */
  margin-bottom: 10px;
}

.column li {
  margin-bottom: 5px;
  font-family: "YourNonBoldFont", sans-serif; /* Replace with your non-bold font family */
  font-weight: lighter; /* Ensures the text is not bold */
}

.image-carousel {
  display: flex;
  overflow-x: scroll;
  padding-bottom: 20px;
  padding-top: 20px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.image-carousel::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

.image-carousel img {
  max-width: 90%;
  width: 300px; /* Set a fixed width */
  height: 200px; /* Set a fixed height */
  object-fit: cover;
  margin-right: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  border-radius: 25px;
}

.description,
.image-description {
  font-family: "YourNonBoldFont", sans-serif; /* Replace with your non-bold font family */
  font-weight: lighter; /* Ensures the text is not bold */
}

/* New styles for image descriptions */
.image-description {
  font-size: 18px; /* Adjust the size as needed */
  color: #333; /* Adjust the color as needed */
  margin-top: 5px;
}

/* Styles for the image container within the carousel to handle description */
.image-carousel > div {
  max-width: 50%;
  margin-right: 5px;
  text-align: center;
}



@media only screen and (max-width: 600px) {
  .content {
    flex-direction: column;
  }

  .column {
    width: 100%;
  }

  .column ol {
    padding-left: 40px; /* Increase padding for better mobile visibility */
  }

  .image-carousel {
    flex-direction: column;
  }

  .image-carousel img {
    max-width: 100%;
    
    margin-bottom: 10px;
  }
  .image-carousel > div {
    max-width: 100%;
    margin-bottom: 10px;
  }
}
</style>

