<template>
  <div class="contact-container">
    <div class="image-side">
      <div class="image-container">
        <img src="../assets/icecream.jpg" alt="Living Room" />
      </div>
    </div>
    
    <div class="contact-form">
      <h2>Thank You!</h2>
      <p> I'll send you an email when it's ready!</p>

      <!-- <p style="font-family: YourNonBoldFont, sans-serif; ">
        IF you find any value in interacting with me (if you learn something 🤔, you get some ideas 🤯, you're entertained 😋, you feel better 🥰 etc), IF you want, and IF you can, you have the option to leave me a donation. ☕  Again, only IF this is a comfortable possibility for you and it brings you joy (please don't do it out of guilt, money carries energy!). Any happy donation is welcomed and much appreciated, as all the funds are going towards getting trained/ certified as a Color Analyst ($1k), buying the materials kit ($2k), misc- monitor calibrator, mirror, camera, etc ($1,500).
      </p>
  
      <p style="font-family: YourNonBoldFont, sans-serif; ">Oh, and of course, I will credit any donations to the equivalent amount of membership months, plus one month free as a thank you for believing in little old me, basically a stranger on the internet, and supporting me into the next level of my life where I spread rainbows 🌈, sparkles ✨ and cheer 🥳🎊!</p>
      
        <button type="submit">Donate</button> -->
    </div>
  </div>
</template>


<script>
import { mapMutations } from 'vuex';

export default {
  name: "ContactForm",
  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        email: ""
      },
    };
  },
  methods: {
    ...mapMutations(['UPDATE_USER_INFO']),
    submitForm() {
      // console.log("Form submitted:", this.form);
      this.UPDATE_USER_INFO(this.form);  // Commit user info to the store
      this.$emit('next');             // Emit the next event to move to the next component
    },
  },
};
</script>



<style scoped>
.contact-container {
  display: flex;
  justify-content: start;
  align-items: center;
  /* padding: 40px; */
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  gap: 20px;
  margin: auto;
  min-height: 500px;
  /* Removed height to allow the container to size with content */
}
.contact-text {
  flex-basis: 50%;
  text-align: left;
}
.contact-text h2,
.contact-text p {
  margin: 0;
  padding: 10px 0;
}
.contact-text h2 {
  font-size: 2.5em;
}
.contact-text p {
  font-size: 1.2em;
}
.contact-form {
  flex-basis: 50%; /* Adjusted to control width, set it to 50% or another value that suits your design */
  background: #fff;
  padding: 20px; /* Reduced padding to allow for more space for the form fields */
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: stretch; /* Align items to stretch to fit the container width */
  /* margin-top: 20vh; Removed to keep the form's original position */
}

.contact-form form div {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.contact-form input,
.contact-form textarea {
  width: calc(100% - 20px); /* Adjust width to account for padding */
  padding: 15px; /* Adjust padding as needed */
  margin-bottom: 10px; /* Adjust spacing between fields */
  border: 1px solid #ddd;
  border-radius: 4px;
}

.contact-form textarea {
  height: auto; /* Make height automatic to expand as needed */
  resize: vertical; /* Allow vertical resizing, optional */
}

.contact-form button {
  background-color: #ee9c1a;
  color: white;
  padding: 15px; /* Increase padding for better visuals */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  max-width: 200px;
  margin-top: 10px; /* Add space above the button if needed */
  font-size: 1.2em;
}

.contact-text .large-text {
  font-size: 1.5em; /* Adjust this value as needed */
}

.image-side {
  flex: 1;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: start; /* Changed from center to start */
  overflow: hidden;
}

.image-container {
  margin-top: 25px;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: start; /* Changed from center to start */
  /* mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0)
  );  */
}

.image-container img {
  height: 70vh;
  width: 50vw; /* Adjust width to maintain aspect ratio */
  object-fit: scale-down;
}

@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    align-items: stretch;
  }

  .image-side {
    height: auto;
    order: -1; /* Makes the image-side appear on top */
  }

  .image-container {
    height: 40vh;
    margin-top: 10px;
    align-items: end;
  }
  .image-container img {
    height: 80%; /* Making the image fill the container */
    width: 100%; /* Making the image fill the container */
    object-fit: cover;
  }

  .contact-form h2 {
    margin-block-start: 5px;
    margin-block-end: 5px;
  }

  .contact-form {
    width: 90%;
    padding: 15px;
  }
}
</style>

