<template>
  <div class="contact-container">
    <div class="image-side">
      <div class="image-container">
        <img src="../assets/icecream.jpg" alt="Living Room" />
      </div>
    </div>

    <div class="contact-form">
      <h2>Almost Done!</h2>
      <form @submit.prevent="submitForm">
        <p>ONLY if you're comfortable:</p>
        <p style="font-family: YourNonBoldFont, sans-serif">
          Can I use your pics 🤳 for my portfolio, post the analysis on my
          website, and on my online social media channels to become famous 📸
          and eventually work with paying clients? 🤩 If not, I totally
          understand, no pressure. :)
        </p>
        <div class="option">
          <label class="checkbox-label">
            <input
              type="checkbox"
              id="portfolioConsent"
              v-model="form.portfolioConsent"
              checked
            />
            <span>Yes, you can use my results in your portfolio </span>
          </label>
        </div>
        <p>Also, I'm going to set up a $25 monthly membership where:</p>
        <ul>
          <li>I answer your weekly Q&As</li>
          <li>
            make content about WHAT to do with your colors once you have them,
          </li>
          <li>how to combine your seasonal colors, and style them</li>
          <li>How to build a functional wardrobe</li>
          <li>
            what to do with the clothes you already have that are not in your
            color palette
          </li>
          <li>
            how to wear colors outside of your color palette without the fashion
            police getting you! 🚨
          </li>
          <li>
            how to transition your current wardrobe from where it is to better
            than it is without extreme measures like clearing your whole closet
            down to the unmentionables.
          </li>
          <li>etc..</li>
        </ul>
        <p>
          If you'd like me to notify you when the membership is up and ready, I
          will email you, or send you a traveling pigeon. 🕊️
        </p>

        <div class="option">
          <label class="checkbox-label">
            <input
              type="checkbox"
              id="membershipInterest"
              v-model="form.membershipInterest"
              checked
            />
            <span>Yes, I'd love to hear more!</span>
          </label>
        </div>
        <button type="submit">Finish!</button>
      </form>
    </div>
  </div>
</template>





<script>
import { mapMutations, mapState } from "vuex";
// Import Firestore correctly
import { getFirestore, collection, addDoc } from "firebase/firestore";

export default {
  name: "ContactForm",
  data() {
    return {
      form: {
        portfolioConsent: false, // Default checked
        membershipInterest: false, // Default checked
        isDownloaded: false,
        isRepliedTo: false
      },
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  methods: {
    ...mapMutations(["UPDATE_USER_INFO"]),
    submitForm() {
      // First update the user info in Vuex
      this.UPDATE_USER_INFO(this.form);

      // Initialize Firestore reference
      const db = getFirestore();

      // Add new document to Firestore
      addDoc(collection(db, "clients"), this.userInfo)
        .then(() => {
          // console.log("Document written with ID: ", docRef.id);
          this.$emit("next"); // Proceed to next action if successful
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    },
  },
};
</script>






<style scoped>
.contact-container {
  display: flex;
  justify-content: start;
  align-items: center;
  /* padding: 40px; */
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  gap: 20px;
  margin: auto;
  min-height: 500px;
  /* Removed height to allow the container to size with content */
}
.contact-text {
  flex-basis: 50%;
  text-align: left;
}
.contact-text h2,
.contact-text p {
  margin: 0;
  padding: 10px 0;
}
.contact-text h2 {
  font-size: 2.5em;
}
.contact-text p {
  font-size: 1.2em;
}
.contact-form {
  flex-basis: 50%; /* Adjusted to control width, set it to 50% or another value that suits your design */
  background: #fff;
  padding: 20px; /* Reduced padding to allow for more space for the form fields */
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: stretch; /* Align items to stretch to fit the container width */
  /* margin-top: 20vh; Removed to keep the form's original position */
  text-align: left;
}

.contact-form form div {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.contact-form input,
.contact-form textarea {
  height: auto; /* Make height automatic to expand as needed */
  resize: vertical; /* Allow vertical resizing, optional */
}

.contact-form button {
  background-color: #ee9c1a;
  color: white;
  padding: 15px; /* Increase padding for better visuals */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px; /* Add space above the button if needed */
}

.contact-text .large-text {
  font-size: 1.5em; /* Adjust this value as needed */
}

.image-side {
  flex: 1;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: start; /* Changed from center to start */
  overflow: hidden;
}

.image-container {
  margin-top: 25px;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: start; /* Changed from center to start */
  /* mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0)
  );  */
}

.image-container img {
  height: 70vh;
  width: 50vw; /* Adjust width to maintain aspect ratio */
  object-fit: cover;
}

.checkbox-label span {
  margin-left: 5px; /* Aligns text with the checkbox, adjust as needed */
}
li {
  margin-bottom: 5px;
  font-family: "YourNonBoldFont", sans-serif; /* Replace with your non-bold font family */
  font-weight: lighter; /* Ensures the text is not bold */
}

@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    align-items: stretch;
  }

  .image-side {
    height: auto;
    order: -1; /* Makes the image-side appear on top */
  }

  .image-container {
    height: 40vh;
    margin-top: 10px;
    align-items: end;
  }
  .image-container img {
    height: 80%; /* Making the image fill the container */
    width: 100%; /* Making the image fill the container */
    object-fit: cover;
  }

  .contact-form h2 {
    margin-block-start: 5px;
    margin-block-end: 5px;
  }

  .contact-form {
    width: 90%;
    padding: 15px;
  }
}
</style>

