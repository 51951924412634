
<script>
import { mapState } from "vuex";

export default {
  name: "buy-items",
  computed: {
    ...mapState(["isMarketplacePreloaded"]),
  },
};
</script>
