<template>
  <div>
    <!-- Dynamic Component based on the current step -->
    <component :is="currentComponent" @next="handleNext" />
  </div>
</template>

<script>
import CtaSection from "./CtaSection.vue";
import ImageUploadOne from "./ImageUploadOne.vue";
import ImageUploadTwo from "./ImageUploadTwo.vue";
import ImageUploadThree from "./ImageUploadThree.vue";
import ImageUploadFour from "./ImageUploadFour.vue";
import ImageUploadFive from "./ImageUploadFive.vue";
import FinalMessage from "./FinalMessage.vue"; // Last component showing final message
import Confirmation from "./Confirmation.vue";

export default {
  data() {
    return {
      step: 0, // Current step index
    };
  },
  computed: {
    components() {
      return [
        
        CtaSection,
        ImageUploadOne, // This component is reused for steps 2 to 6
        ImageUploadTwo,
        ImageUploadThree,
        ImageUploadFour,
        ImageUploadFive,
        FinalMessage,Confirmation,
      ];
    },
    currentComponent() {
      return this.components[this.step];
    },
  },
  methods: {
    handleNext() {
      if (this.step < this.components.length - 1) {
        this.step++;
      } else {
        this.step = 0; // or handle completion
      }
    },
  },
};
</script>

