<template>
  <div class="fixed-footer">© 2024 Color Analysis Love. All rights reserved.</div>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style scoped>
.fixed-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #38af73; /* Dark background for the footer */
  color: white; /* White text color */
  text-align: center;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2); /* Adds a slight shadow to the top of the footer */
}

/* You might want to add media queries to adjust the layout on smaller screens */
@media (max-width: 600px) {
  .fixed-footer {
    font-size: 14px; /* Smaller font size on small screens */
  }
}
</style>

