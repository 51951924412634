<template>
  <div v-if="showModal" class="modal-overlay" @click.self="closeModal">
    <div class="modal-content">
      <!-- Make sure :src is bound correctly to imageSrc -->
      <img :src="imageSrc" :alt="imageAlt" class="modal-image" />
      <p style="font-family: YourNonBoldFont, sans-serif; margin: 15px">
        {{ imageDescription }}
      </p>
      <button class="modal-close" @click="closeModal">Close</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showModal: Boolean,
    imageSrc: String,
    imageAlt: String,
    imageDescription: String,
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 25px;
  text-align: center;
  max-width: 50%;
}

.modal-image {
  max-width: 80%;
  max-height: 40vh;
  border-radius: 25px;
}

.modal-description {
  margin-top: 15px;
}

.modal-close {
    padding: 15px 25px; /* Bigger button for easier interaction */
    background-color: #ee9c1a; /* Default color for all buttons except 'Next' */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    /* margin: 20px 10px;  */
    font-size: 1.2em;
  }

@media (max-width: 600px) {
  .modal-content {
    max-width: 90%; /* Increase width for small screens */
    padding: 10px; /* Reduce padding */
    height: auto;
    align-content: center;
  }

  .modal-image {
    height: auto; /* Adjust image height on smaller screens */
  }

  .modal-close {
    padding: 15px 25px; /* Bigger button for easier interaction */
    background-color: #ee9c1a; /* Default color for all buttons except 'Next' */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    /* margin: 20px 10px;  */
    font-size: 1.2em;
  }
}
</style>
