<template>
  <div class="kept-house-container">
    <!-- Hero Section -->
    <div class="hero-section">
      <div class="image-side">
        <div class="image-container">
          <img src="../assets/logo.png" alt="Estate Transition" />
        </div>
      </div>
      <div class="text-side">
        <div class="text-content-wrapper">
          <h1>Simplify Your Estate Transition with Kept House</h1>
          <p>
            Transitioning or downsizing your estate? Let us navigate you through this significant change with ease and optimal financial return.
          </p>
          <button class="btn">Schedule a Walkthrough</button>
        </div>
      </div>
    </div>

    <!-- Services Section -->
    <div class="section">
      <h2>Our Services Include:</h2>
      <div class="content-wrapper">
        <div class="service" v-for="service in services" :key="service.title">
          <img :src="service.image" :alt="service.title" class="service-image">
          <div>
            <h3>{{ service.title }}</h3>
            <p>{{ service.description }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Pricing Section -->
    <div class="section">
      <h2>Transparent Pricing</h2>
      <div class="content-wrapper">
        <div class="pricing-option" v-for="price in pricing" :key="price.title">
          <h3>{{ price.title }}</h3>
          <p>{{ price.detail }}</p>
        </div>
      </div>
    </div>

    <!-- Process Section -->
    <div class="section">
      <h2>How It Works</h2>
      <div class="content-wrapper process">
        <div v-for="step in process" :key="step.title">
          <h3>{{ step.title }}</h3>
          <p>{{ step.description }}</p>
        </div>
      </div>
    </div>

    <!-- CTA Section -->
    <div class="cta-section">
      <h2>Why Choose Kept House?</h2>
      <p>From valuation to sale and clear-out, we offer a complete package tailored to your unique situation.</p>
      <button class="btn">Contact Us Today</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'piece-of-mind',
  data() {
    return {
      services: [
        { title: 'High-Value Auction Partnerships', image: '../assets/deciding.jpg', description: 'Collaborate with top auction houses to ensure the best returns.' },
        { title: 'Estate Sales & Online Auctions', image: '@/assets/deciding.jpg', description: 'From planning to execution, ensuring your items find the right buyers.' },
        { title: 'Home Clear-out Management', image: '@/assets/deciding.jpg', description: 'Efficiently manage the clear-out of your home, minimizing costs through donations and strategic disposals.' },
        { title: 'Estate Inventory & Valuation', image: '@/assets/deciding.jpg', description: 'Receive a detailed inventory with photographs, valuations, and recommendations for each item.' },
        { title: 'Tailored Marketing Campaigns', image: '@/assets/deciding.jpg', description: 'Our targeted marketing strategies promote your sale to the right audience, maximizing visibility and returns.' }
      ],
      pricing: [
        { title: 'Whole Estate Transition Packages', detail: 'Starting at $1,000 + Revenue Share. Includes inventory, campaign, auction, estate sale, donation, and home clear-out management.' },
        { title: 'Whole Home Auctions & Estate Sales', detail: 'Starting at $500, plus a 50/50 sale split. Includes inventory and marketing for in-person or online auctions and sales.' },
        { title: 'Home Clear-out Management', detail: 'Starting at $250. Minimize your clear-out costs through our donation network.' },
        { title: 'Estate Liaison', detail: 'Starting at $500. We manage your estate, coordinating with legal, real estate, and other professionals.' },
        { title: 'Consignment & Staging', detail: 'Services start at $150 and $250, respectively, for smaller lot consignments or home staging for sale.' }
      ],
      process: [
        { title: 'Consultation', description: 'Begin with a personalized consultation to understand your needs.' },
        { title: 'Inventory & Valuation', description: 'Catalog and value your items for a clear inventory.' },
        { title: 'Marketing & Sale', description: 'Design and launch a marketing campaign, managing the sale or auction.' },
        { title: 'Clear-Out & Donation', description: 'Handle the clear-out, with donations and responsible disposals.' },
        { title: 'Final Settlement', description: 'Receive a detailed report and your share of the proceeds promptly.' }
      ],
    };
  },
};
</script>

<style scoped>
.kept-house-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
}

.hero-section, .services-section, .service, .pricing-section, .cta-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 40px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.image-side, .text-side {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img, .service-image {
  max-width: 90%;
  height: auto;
  border-radius: 8px;
}

.text-content-wrapper {
  padding: 20px;
  max-width: 600px;
}

h1, h2, h3 {
  /* color: #007bff; */
  margin: 10px 0;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

p {
  font-size: 1rem;
  line-height: 1.5;
  color: #666;
}

.btn {
  padding: 12px 24px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  background-color: #deb128;
  color: white;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #6a4039;
}

@media (max-width: 768px) {
  .hero-section, .services-section, .service, .pricing-section, .cta-section {
    flex-direction: column;
    align-items: center;
  }

  .image-side, .text-side {
    flex: none;
    width: 100%;
  }

  .text-content-wrapper, .image-container img, .service-image {
    padding: 10px;
  }

  h1 {
    font-size: 2rem;
  }

  h2, h3 {
    font-size: 1.5rem;
  }

  p {
    font-size: 0.9rem;
  }

  .btn {
    font-size: 16px;
  }

  .section {
  background-color: #fff;
  padding: 40px;
  margin: 20px 0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.content-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.service, .pricing-option, .process > div {
  flex-basis: 45%;
  margin: 10px 0;
}

.service img, .pricing-option img {
  width: 100%;
  max-width: 200px;
  height: auto;
  margin-right: 20px;
}

.process > div {
  flex-basis: 100%;
}

@media (max-width: 768px) {
  .content-wrapper, .service, .pricing-option, .process > div {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .service img, .pricing-option img {
    margin-bottom: 20px;
  }

  .service, .pricing-option, .process > div {
    flex-basis: 100%;
  }
}
}
</style>

