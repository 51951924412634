import { createRouter, createWebHistory } from 'vue-router';

// Import components
import Buy from './components/Buy.vue';
import Sell from './components/Sell.vue';
import Events from './components/Events.vue';
import Partnerships from './components/Partnerships.vue';
import PeaceOfMind from './components/PeaceOfMind.vue';
import Home from './components/Home.vue';
import Contact from './components/Contact.vue';

// Define routes
const routes = [
    {
        path: '/buy',
        name: 'buy-items',
        component: Buy,
    },
    {
        path: '/contact',
        name: 'ContactForm',
        component: Contact,
    },
    {
        path: '/sell',
        name: 'sell-items',
        component: Sell,
    },
    {
        path: '/events',
        name: 'local-events',
        component: Events,
    },
    {
        path: '/partnerships',
        name: 'kept-partnerships',
        component: Partnerships,
    },
    {
        path: '/peace-of-mind',
        name: 'peace-of-mind',
        component: PeaceOfMind,
    },
    {
        path: '/:catchAll(.*)', // Updated for Vue Router 4 syntax
        redirect: '/',
    },
    {
        path: '/',
        name: 'home-page',
        component: Home, // Set Home component for the root path
    },
];

// Create router instance
const router = createRouter({
    history: createWebHistory(), // Use browser history
    routes,
});

export default router;
