<template>
  <div id="app">
    <!-- <Header /> -->
    <div class="container">
      <!-- Global Iframe that only shows on the Buy route -->

      <router-view />
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import FooterComponent from "./components/footer.vue"; // Ensure this matches the actual file name
// import Header from "./components/header.vue"; // Ensure this matches the actual file name

export default {
  name: "App",
  components: {
    // Header,
    FooterComponent,
  },
};
</script>

<style>
.container {
  padding-top: 0px; /* Adjust based on the height of your header */
  display: flex;
  flex-direction: column; /* Adjust if your content layout is different */
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
