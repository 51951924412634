import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      userInfo: {}
    };
  },
  mutations: {
    // Update userInfo incrementally
    UPDATE_USER_INFO(state, payload) {
      // Merging existing userInfo with new payload
      state.userInfo = { ...state.userInfo, ...payload };
      // console.log("Printing State")
      // console.log(state.userInfo)
    },
  },
  
});

export default store;
