import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store';
import './assets/styles/global.css';

// Firebase imports
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
// import { getAnalytics } from "firebase/analytics";

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyA4FR3Lgra2QouNQrDRhwm1V-D133dOc1s",
    authDomain: "color-analysis-love.firebaseapp.com",
    projectId: "color-analysis-love",
    storageBucket: "color-analysis-love.appspot.com",
    messagingSenderId: "569226628438",
    appId: "1:569226628438:web:5ac296133164ed819edaf7",
    measurementId: "G-14PBR240JQ"
  };

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);

// // Optionally enable analytics if it's part of your project
// if (process.env.NODE_ENV === 'production') {
//   const analytics = getAnalytics(firebaseApp);
// }

const app = createApp(App);
app.use(firebaseApp)
app.use(store);
app.use(router);
app.mount('#app');

app.config.globalProperties.$db = db;