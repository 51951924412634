<template>
  <div class="event-list-container">
    <!-- Header -->
    <header class="event-list-header">
      <h1>Upcoming Events</h1>
    </header>

    <!-- Loading indicator -->
    <div v-if="isLoadingEvents" class="loading-indicator">
      Loading events...
    </div>

    <!-- Check if events are loaded and have length -->
    <div v-else-if="events.length > 0" class="event-list">
      <div
        v-for="(event, index) in sortedEvents"
        :key="index"
        class="event-row"
      >
        <!-- Left column for date -->
        <div class="event-date-column">
          <div class="event-month">{{ eventMonth(event.dateTime) }}</div>
          <div class="event-day">{{ eventDay(event.dateTime) }}</div>
        </div>
        <!-- Right column for event info -->
        <div class="event-info-column">
          <div class="event-time">{{ eventTime(event.dateTime) }}</div>
          <div class="event-details">
            <h2>{{ event.name }}</h2>
            <p>{{ event.description }}</p>
            <div class="event-location">{{ event.address }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- Show placeholder if no events are present -->
    <div v-else class="event-placeholder">No Current Events Scheduled</div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "EventList",
  computed: {
    ...mapState(["events", "isLoadingEvents"]),
    sortedEvents() {
      const sorted = [...this.events].sort(
        (b, a) => new Date(b.dateTime) - new Date(a.dateTime)
      );
      console.log(sorted); // Debugging output
      return sorted;
    },
  },
  methods: {
    eventMonth(dateString) {
      const date = new Date(dateString);
      return date
        .toLocaleDateString(undefined, { month: "short" })
        .toUpperCase();
    },
    eventDay(dateString) {
      const date = new Date(dateString);
      return date.getDate();
    },
    eventTime(dateString) {
      const date = new Date(dateString);
      return date
        .toLocaleTimeString(undefined, {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        })
        .toLowerCase();
    },
  },
  mounted() {
    if (!this.events.length) {
      this.$store.dispatch('fetchEvents');
    }
  },
};
</script>



<style>
.event-list-container {
  overflow-y: auto;
  height: 100%; /* Set a height or make it responsive based on its parent container */
}

.event-card {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 10px;
  padding: 15px;
}

.event-date {
  font-weight: bold;
}

.event-info h2 {
  margin: 0;
  color: #333;
}

.event-placeholder {
  text-align: center;
  margin: 20px;
  font-size: 20px;
  color: #666;
}

.event-list {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-right: 25px;
  margin-left: 15%;
  margin-bottom: 10px;
}

.event-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px; /* Adjust as needed */
}

.event-date-column {
  text-align: center;
  margin-right: 15%; /* Space between columns */
}

.event-weekday {
  font-size: 1.2em; /* Adjust as needed */
  font-weight: bold;
}

.event-day {
  font-size: 2.5em; /* Adjust as needed */
  line-height: 1;
}

.event-info-column {
  flex-grow: 1;
}

.event-date-column {
  text-align: center;
  margin-right: 20px; /* Adjusted space between columns */
}

.event-month {
  font-size: 1em; /* Adjust as needed */
  font-weight: bold;
}

.event-day {
  font-size: 2.5em; /* Adjust as needed */
  line-height: 1;
}

.event-info-column {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Aligns content vertically center */
}

.event-details h2 {
  margin: 0;
  color: #333;
}

.event-list-header {
  background: #f5f5f5; /* Light grey background */
  padding: 10px;
  text-align: center;
  
  font-size: 24px; /* Example font size, adjust as needed */
}

.event-list-container {
  overflow-y: auto;
  height: 100%; /* Set a height or make it responsive based on its parent container */
  width: 80%;
  padding: 20px; /* Ample space around the cards */
  align-self: center;
}

.event-row {
  background: #fff; /* White background for the event card */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle drop shadow */
  border-radius: 8px; /* Rounded corners for the card */
  padding: 20px; /* Ample padding inside the card */
  margin-bottom: 20px; /* Space between event cards */
  max-width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.event-date-column {
  text-align: center;
  margin-right: 20px; /* Adjusted space between columns */
}

.event-month {
  font-size: 1em; /* Adjust as needed */
  font-weight: bold;
  color: #333; /* Dark text for month */
}

.event-day {
  font-size: 2.5em; /* Adjust as needed */
  line-height: 1;
  color: #333; /* Dark text for day */
}

.event-info-column {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Aligns content vertically center */
}

.event-details h2 {
  margin: 0 0 10px 0; /* Added space below the event title */
  color: #333;
}

.event-time {
  font-size: 1em; /* Adjust as needed */
  color: #555; /* Dark gray for time */
  margin-bottom: 5px; /* Space above the event details */
}

.event-details p {
  margin: 0 0 10px 0; /* Space below the description */
  color: #666; /* Gray text for description */
}

.event-location {
  font-size: 0.9em; /* Smaller font size for location */
  color: #777; /* Lighter gray for location */
}

/* Add additional styling as needed to match the provided design */
</style>
