<template>
  <div class="contact-container">
    <div class="contact-text">
      <h2>Contact us</h2>
      <p>
        Need to get in touch with us? Fill out the form with your inquiry and
        we'll try to get back to you within 48 hours.

        <br />
        Or call us at:
        <br />
        <br />
        <span class="phone-icon">📞</span>
        <span class="large-text"> 513-609-4731</span>
      </p>
    </div>
    <div class="contact-form">
      <form @submit.prevent="submitForm">
        <div>
          <input
            type="text"
            v-model="form.firstName"
            placeholder="First name*"
            required
          />
          <input
            type="text"
            v-model="form.lastName"
            placeholder="Last name*"
            required
          />
          <input
            type="email"
            v-model="form.email"
            placeholder="Email*"
            required
          />
          <textarea
            v-model="form.message"
            rows="4"
            placeholder="What can we help you with?"
          ></textarea>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  </div>
</template>


<script>
export default {
  name: "ContactForm",
  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        email: "",
        message: "",
      },
    };
  },
  methods: {
    submitForm() {
      console.log("Form submitted:", this.form);
      this.sendEmail({
        to: "jayson@paglow.com", // Assuming this is the recipient email
        subject: "New Contact Form Submission",
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        email: this.form.email,
        message: this.form.message,
      });
    },
    async sendEmail(data) {
      try {
        const response = await fetch(
          "https://thekeptestate.pythonanywhere.com/contactForm",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );
        if (!response.ok) throw new Error("Network response was not ok.");
        this.form = { firstName: "", lastName: "", email: "", message: "" }; // Clear form
        alert("Your message has been sent successfully!");
      } catch (error) {
        console.error("Error sending email:", error);
        alert("There was an error sending your message. Please try again.");
      }
    },
  },
};
</script>

<style scoped>
.contact-container {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 40px;
  gap: 20px;
  margin: auto;
  /* Removed height to allow the container to size with content */
}
.contact-text {
  flex-basis: 50%;
  text-align: left;
}
.contact-text h2,
.contact-text p {
  margin: 0;
  padding: 10px 0;
}
.contact-text h2 {
  font-size: 2.5em;
}
.contact-text p {
  font-size: 1.2em;
}
.contact-form {
  flex-basis: 50%; /* Adjusted to control width, set it to 50% or another value that suits your design */
  background: #fff;
  padding: 20px; /* Reduced padding to allow for more space for the form fields */
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: stretch; /* Align items to stretch to fit the container width */
  /* margin-top: 20vh; Removed to keep the form's original position */
}

.contact-form form div {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.contact-form input,
.contact-form textarea {
  width: calc(100% - 20px); /* Adjust width to account for padding */
  padding: 15px; /* Adjust padding as needed */
  margin-bottom: 10px; /* Adjust spacing between fields */
  border: 1px solid #ddd;
  border-radius: 4px;
}

.contact-form textarea {
  height: auto; /* Make height automatic to expand as needed */
  resize: vertical; /* Allow vertical resizing, optional */
}

.contact-form button {
  background-color: #5f27cd;
  color: white;
  padding: 15px; /* Increase padding for better visuals */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px; /* Add space above the button if needed */
}

.contact-text .large-text {
  font-size: 1.5em; /* Adjust this value as needed */
}
</style>

