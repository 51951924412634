<template>
  <div class="kept-house-container">
    <!-- Hero Section -->
    <div class="hero-section">
      <div class="image-side">
        <div class="image-container">
          <img src="../assets/logo.png" alt="Estate Transition" />
        </div>
      </div>
      <div class="text-side">
        <div class="text-content-wrapper">
          <h1>Simplify Your Estate Transition with Kept House</h1>
          <p>
            Transitioning or downsizing your estate? Let us navigate you through
            this significant change with ease and optimal financial return.
          </p>
          <router-link to="/contact" class="contact-button"><button class="btn">Get Started</button></router-link>
        </div>
      </div>
    </div>

    <!-- Services Section -->
    <div class="section">
      <h2>Our Services Include:</h2>
      <div class="content-wrapper">
        <div
          class="service"
          v-for="(service, index) in services"
          :key="service.title"
          :class="getServiceClass(index)"
        >
          <img
            :src="service.image"
            :alt="service.title"
            class="service-image"
          />
          <div class="service-text">
            <h3>{{ service.title }}</h3>
            <p>{{ service.description }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Pricing Section -->
    <div class="section pricing-section">
      <div class="content-wrapper">
        <div class="text-side">
          <h2>Transparent Pricing</h2>
          <div
            class="pricing-option"
            v-for="price in pricing"
            :key="price.title"
          >
            <h3>{{ price.title }}</h3>
            <p>{{ price.detail }}</p>
          </div>
        </div>
        <div class="image-side">
          <img src="../assets/logo.png" alt="Pricing Image" />
        </div>
      </div>
    </div>

    <!-- Process Section -->
    <div class="section process-section">
      <div class="content-wrapper">
        <div class="image-side">
          <img src="../assets/logo.png" alt="Process Image" />
        </div>
        <div class="text-side">
          <h2>How It Works</h2>
          <div v-for="step in process" :key="step.title">
            <h3>{{ step.title }}</h3>
            <p>{{ step.description }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- CTA Section -->
    <div class="cta-section">
      <h2>Why Choose Kept House?</h2>
      <p>
        From valuation to sale and clear-out, we offer a complete package
        tailored to your unique situation.
      </p>
      <router-link to="/contact" class="contact-button"><button class="btn">Get Started</button></router-link>
    </div>
  </div>
</template>

<script>
import decidingImage from "../assets/logo.png";
import ecom from "../assets/logo.png";
import auction from "../assets/logo.png";
import moving from "../assets/logo.png";
import marketing from "../assets/logo.png";

export default {
  name: "KeptHouseComponent",
  data() {
    return {
      services: [
        {
          title: "High-Value Auction Partnerships",
          image: auction,
          description:
            "Collaborate with top auction houses to ensure the best returns.",
        },
        {
          title: "Estate Sales & Online Sales",
          image: ecom,
          description:
            "From planning to execution, ensuring your items find the right buyers.",
        },
        {
          title: "Home Clear-out Management",
          image: moving,
          description:
            "Efficiently manage the clear-out of your home, minimizing costs through donations and strategic disposals.",
        },
        {
          title: "Estate Inventory & Valuation",
          image: decidingImage,
          description:
            "Receive a detailed inventory with photographs, valuations, and recommendations for each item.",
        },
        {
          title: "Tailored Marketing Campaigns",
          image: marketing,
          description:
            "Our targeted marketing strategies promote your sale to the right audience, maximizing visibility and returns.",
        },
      ],
      pricing: [
        {
          title: "Whole Estate Transition Packages",
          detail:
            "Starting at $1,000 + Revenue Share. Includes inventory, campaign, auction, estate sale, donation, and home clear-out management.",
        },
        {
          title: "Whole Home Auctions & Estate Sales",
          detail:
            "Starting at $500, plus a 50/50 sale split. Includes inventory and marketing for in-person or online auctions and sales.",
        },
        {
          title: "Home Clear-out Management",
          detail:
            "Starting at $250. Minimize your clear-out costs through our donation network.",
        },
        {
          title: "Estate Liaison",
          detail:
            "Starting at $500. We manage your estate, coordinating with legal, real estate, and other professionals.",
        },
        {
          title: "Consignment & Staging",
          detail:
            "Services start at $150 and $250, respectively, for smaller lot consignments or home staging for sale.",
        },
      ],
      process: [
        {
          title: "Consultation",
          description:
            "Begin with a personalized consultation to understand your needs.",
        },
        {
          title: "Inventory & Valuation",
          description: "Catalog and value your items for a clear inventory.",
        },
        {
          title: "Marketing & Sale",
          description:
            "Design and launch a marketing campaign, managing the sale or auction.",
        },
        {
          title: "Clear-Out & Donation",
          description:
            "Handle the clear-out, with donations and responsible disposals.",
        },
        {
          title: "Final Settlement",
          description:
            "Receive a detailed report and your share of the proceeds promptly.",
        },
      ],
    };
  },
  computed: {
    getServiceClass() {
      return function (index) {
        return {
          "service-left": index % 2 === 0,
          "service-right": index % 2 !== 0,
        };
      };
    },
  },
};
</script>

<style scoped>
.kept-house-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
}

.hero-section,
.services-section,
.service,
.pricing-section,
.cta-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 90vw;
  padding: 20px;
  /* max-width: 90vw; */
  margin-bottom: 40px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.image-side,
.text-side {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  max-width: 90%;
  height: auto;
  border-radius: 8px;
}

.service-image {
  max-width: 30%;
  height: auto;
  border-radius: 8px;
}

.text-content-wrapper {
  padding: 20px;
  max-width: 600px;
}

h1,
h2,
h3 {
  /* color: #007bff; */
  margin: 10px 0;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

p {
  font-size: 1rem;
  line-height: 1.5;
  color: #666;
  margin-right: 25px;
}

.btn {
  padding: 12px 24px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  background-color: #deb128;
  color: white;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #6a4039;
}

@media (max-width: 768px) {
  .hero-section,
  .services-section,
  .service,
  .pricing-section,
  .cta-section {
    flex-direction: column;
    align-items: center;
  }

  .image-side,
  .text-side {
    flex: none;
    width: 100%;
  }

  .text-content-wrapper,
  .image-container img,
  .service-image {
    padding: 10px;
  }

  h1 {
    font-size: 2rem;
  }

  h2,
  h3 {
    font-size: 1.5rem;
  }

  p {
    font-size: 0.9rem;
  }

  .btn {
    font-size: 16px;
  }

  .section {
    background-color: #fff;
    padding: 40px;
    margin: 20px 0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .content-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .service,
  .pricing-option,
  .process > div {
    flex-basis: 45%;
    margin: 10px 0;
  }

  .service img,
  .pricing-option img {
    width: 100%;
    max-width: 200px;
    height: auto;
    margin-right: 20px;
  }

  .process > div {
    flex-basis: 100%;
  }

  @media (max-width: 768px) {
    .content-wrapper,
    .service,
    .pricing-option,
    .process > div {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .service img,
    .pricing-option img {
      margin-bottom: 20px;
    }

    .service,
    .pricing-option,
    .process > div {
      flex-basis: 100%;
    }
  }
}

.service {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.service:nth-child(even) {
  flex-direction: row-reverse;
}

.service-image {
  flex-shrink: 0;
  width: 45%; /* Adjust image width as needed */
  margin: 20px 20px;
}

.service-text {
  flex-grow: 1;
  width: 50%;
  padding: 0 15px;
}

.service:nth-child(even) .service-text {
  text-align: left;
}

.service:nth-child(odd) .service-text {
  text-align: right;
}

.pricing-section .content-wrapper,
.process-section .content-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: stretch; /* Ensure items fill the container height */
}

.pricing-section .image-side img,
.process-section .image-side img {
  width: 50vw; /* Image takes 50% of the viewport width */
  height: 100%; /* Stretch to fill the container's height */
  object-fit: cover; /* Adjust as needed to maintain aspect ratio */
}

.text-side,
.image-side {
  flex: 1; /* Both sides take up equal space */
}

.pricing-section .content-wrapper,
.process-section .content-wrapper {
  display: flex;
  flex-direction: row; /* Ensure that the content is laid out in a row */
  align-items: stretch; /* Stretch items to fill the container height */
  margin: 20px;
  /* margin-bottom: 20px; */
}

.pricing-section .text-side,
.process-section .text-side {
  display: flex;
  flex-direction: column; /* Align text content in a column */
  justify-content: center; /* Center content vertically */
  align-items: flex-start; /* Align items to the start of the flex container */
  width: 50%; /* Text side takes up 50% of the container width */
}

.pricing-section .image-side,
.process-section .image-side {
  width: 50%; /* Image side takes up 50% of the container width */
}

.pricing-section .image-side img,
.process-section .image-side img {
  width: 100%; /* Image takes full width of its container */
  height: 100%; /* Stretch to fill the container's height */
  object-fit: cover; /* Adjust as needed to maintain aspect ratio */
}

.process-section .text-side {
  justify-content: center; /* Keeps content vertically centered */
  align-items: flex-end; /* Aligns content to the right */
  padding: 0 10% 0 5%; /* Adjust the padding to add space between the text and the image */
  text-align: right; /* Aligns the text to the right */
}

.cta-section {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers the items horizontally */
  width: 100%; /* Takes the full width */
  padding: 40px 0; /* Adds vertical padding to the top and bottom */
}

.cta-section h2,
.cta-section p,
.cta-section {
  width: 100%; /* Makes each item take full width of the container */
  text-align: center; /* Centers the text */
  margin-bottom: 20px; /* Adds some space between the elements */
}
.cta-btn {
  width: 10%; /* Makes each item take full width of the container */
  text-align: center; /* Centers the text */
  margin-bottom: 20px; /* Adds some space between the elements */
}

/* Last element should not have margin-bottom */
.cta-section .btn {
  margin-bottom: 0;
}
</style>

