<!-- Home.vue -->
<template>
  <div class="home-page">
    <!-- Hero Section -->
    <HeroSection @scrollToHowSection="scrollToHowSection" />
    <HowSection ref="howSection" />
    <CtaManager/>
  </div>
</template>

<script>
import HeroSection from "@/sections/HeroSection.vue";
import HowSection from "@/sections/HowSection.vue";
import CtaManager from "@/sections/CtaManager.vue";

export default {
  name: "HomePage",
  components: {
    HeroSection,
    HowSection,
    CtaManager
  },
  methods: {
    scrollToHowSection() {
      const howSection = this.$refs.howSection;
      if (howSection) {
        const top = howSection.$el.offsetTop;
        window.scrollTo({
          top: top,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style>
</style>
