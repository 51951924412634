<template>
  <div class="hero-section">
    <img class="logo" src="../assets/logo.png" alt="Color Analyses Love" />
    <div class="text-side">
      <div class="text-content-wrapper">
        <h1>Next Steps <br /></h1>
        <p style="font-size: 24px">
          Thank you for playing with colors<br /> with me! 😃🌈
        </p>
        <button class="btn" id="sell-button" @click="scrollToHowSection">
          Let's Get Started!
        </button>
      </div>
    </div>
    <div class="image-side">
      <div class="image-container">
        <img src="../assets/circleTwo.png" alt="Living Room" />
      </div>
    </div>
  </div>
</template>


<script>
export default {
  methods: {
    scrollToHowSection() {
      // console.log("Emitting event to scroll to How Section");
      this.$emit("scrollToHowSection");
    },
  },
};
</script>


<style scoped>
.hero-section {
  display: flex;
  height: 100vh;
  align-items: center; /* Changed from center to start */
  justify-content: space-between;
  padding: 0px;
  box-sizing: border-box;
}

.text-side {
  flex: 1;
  padding-right: 15px;
  /* margin-bottom: 10rem; */
  margin-left: 30px;
}

.text-side h1 {
  font-size: 3rem; /* Increased font size for the h1 element */
  line-height: 1.2; /* Adjust line height for better readability */
  margin: 0 0 20px; /* Adjust margin as needed */
}

.text-side {
  display: flex; /* Ensure .text-side is a flex container */
  flex: 1;
  height: 80vh;
  width: 20vw;
  padding-right: 15px;
  padding-left: 10px;
  /* margin-left: 8%; */
  margin-top: 4%;
  align-items: center; /* Vertically center .text-content-wrapper */
  justify-content: center; /* Horizontally center, adjust if needed */
}

.text-content-wrapper {
  text-align: left; /* Align the text to the left */
  max-width: 100%; /* Adjust max width as necessary */
}

/* Additional styles for buttons */
.btn {
  margin-top: 10px; /* Adjust spacing between paragraph and buttons */
  padding: 10px 20px; /* Button padding */
  font-size: 22px; /* Text size inside buttons */
  cursor: pointer; /* Change cursor on hover */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s; /* Smooth background color transition */
}

#sell-button {
  background-color: #ee9c1a; /* Red background for Sell button */
  color: white; /* White text color */
}

#sell-button:hover {
  background-color: #b92d31; /* Darker shade on hover */
}

#buy-button {
  background-color: #1e1e1f; /* Green background for Buy button */
  color: white; /* White text color */
  margin-left: 10px; /* Space between buttons */
}

#buy-button:hover {
  background-color: #959298; /* Darker shade on hover */
}

.image-side {
  flex: 1;
  height: 100vh;
  display: flex;
  justify-content: center;
  /* align-items: start;  */
  overflow: hidden;
  border-radius: 25px;
}

.image-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: start; /* Changed from center to start */

  /* border-radius: 25px; */
}

.image-container img {
  height: 90vh;
  width: auto; /* Adjust width to maintain aspect ratio */
  border-radius: 25px;
  margin-right: 100px;
  /* object-fit: cover; */
}
.hero-section {
  position: relative; /* Add this to make sure the logo is positioned relative to this container */
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  box-sizing: border-box;
}

.logo {
  position: absolute; /* Absolute positioning */
  top: 10px; /* Distance from the top */
  left: 10px; /* Distance from the left */
  width: 20vw; /* Adjust width as needed, or use a specific value */
  z-index: 10; /* Make sure it's on top of other content if needed */
}

@media (max-width: 768px) {
  .image-side {
    display: none;
  }
  .text-side {
    display: flex; /* Ensure .text-side is a flex container */
    flex: 1;
    height: 80vh;
    padding-right: 15px;
    /* margin-left: 8%; */
    padding-left: 50px;
    margin-top: 12%;
    align-items: center; /* Vertically center .text-content-wrapper */
    justify-content: center; /* Horizontally center, adjust if needed */
  }
  .logo {
    width: 75vw;
    left: 50px
    
  }
}
</style>
