<template>
  <div class="contact-container">
    <div class="image-side">
      <div class="image-container">
        <img
          :src="uploadedImageUrl || getDefaultImage"
          alt="Uploaded or Default Image"
        />
      </div>
    </div>

    <div class="contact-form">
      <h2>Inside of the forearm</h2>
      <p style="font-family: YourNonBoldFont, sans-serif">
        I'm not looking for wrist veins- we all know that's too confusing to
        work, right?! I'm looking for a patch of skin that generally doesn't get
        redness/ inflammation, as that can skew us into thinking we're
        automatically cool, particularly if we spent time in the sun, have
        rosacea, have run a mile before breakfast etc. Pinkness can exist on all
        skin tone colors, but it's not necessarily the skin color's temperature.
      </p>
      <p>***IMPORTANT***</p>
      <p style="font-family: YourNonBoldFont, sans-serif">For each image make sure you include a white piece of blank paper to make sure your color analysis comes out the best it can! If you don't like the picture you take
        press the reupload button to try again!</p>
      <input
        type="file"
        ref="fileInput"
        @change="uploadImage"
        accept="image/*"
        style="display: none"
      />
      <div class="button-container">
        <!-- Modified to use class for styling -->
        <button v-if="!uploadedImageUrl" @click="triggerFileInput">
          Upload
        </button>
        <div v-if="uploadedImageUrl">
          <button @click="triggerFileInput">Reupload</button>
          <button @click="saveAndProceed" class="next">Next</button>
          <!-- Class added for specific styling -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { mapMutations } from "vuex";

export default {
  name: "ImageUploadForm",
  data() {
    return {
      uploadedImageUrl: null,
    };
  },
  computed: {
    getDefaultImage() {
      return require("../assets/imageFour.jpg");
    },
  },
  methods: {
    ...mapMutations(["UPDATE_USER_INFO"]),
    triggerFileInput() {
      this.$refs.fileInput.click(); // Triggers the real file input
    },
    uploadImage(event) {
      const file = event.target.files[0];
      if (!file) return;

      const storage = getStorage();

      // Generate a random 12-digit number
      const randomNumber = Math.floor(
        100000000000 + Math.random() * 900000000000
      );

      // Convert the number to a string and use it as a folder name
      const storageRef = ref(storage, `images/${randomNumber}/${file.name}`);

      uploadBytes(storageRef, file)
        .then((snapshot) => {
          getDownloadURL(snapshot.ref).then((url) => {
            this.uploadedImageUrl = url;
            // console.log("Uploaded and image URL received:", url);
          });
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
        });
    },
    reupload() {
      this.$refs.fileInput.value = "";
      this.uploadedImageUrl = null;
    },
    saveAndProceed() {
      this.UPDATE_USER_INFO({ imageFour: this.uploadedImageUrl });
      // console.log("Image saved to store:", this.uploadedImageUrl);
      this.$emit("next");
    },
  },
};
</script>

<style scoped>
.contact-container {
  display: flex;
  justify-content: start;
  align-items: center;
  /* padding: 40px; */
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  gap: 20px;
  margin: auto;
  min-height: 500px;
  /* Removed height to allow the container to size with content */
}
.contact-text {
  flex-basis: 50%;
  text-align: left;
}
.contact-text h2,
.contact-text p {
  margin: 0;
  padding: 10px 0;
}
.contact-text h2 {
  font-size: 2.5em;
}
.contact-text p {
  font-size: 1.2em;
}
.contact-form {
  flex-basis: 50%; /* Adjusted to control width, set it to 50% or another value that suits your design */
  background: #fff;
  padding: 20px; /* Reduced padding to allow for more space for the form fields */
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: stretch; /* Align items to stretch to fit the container width */
  /* margin-top: 20vh; Removed to keep the form's original position */
}

.contact-form form div {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.contact-form input,
.contact-form textarea {
  width: calc(100% - 20px); /* Adjust width to account for padding */
  padding: 15px; /* Adjust padding as needed */
  margin-bottom: 10px; /* Adjust spacing between fields */
  border: 1px solid #ddd;
  border-radius: 4px;
}

.contact-form textarea {
  height: auto; /* Make height automatic to expand as needed */
  resize: vertical; /* Allow vertical resizing, optional */
}

.contact-form button {
  background-color: #ee9c1a;
  color: white;
  padding: 20px 30px; /* Increased padding to make buttons larger */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px; /* Increased space above the button */
  margin-right: 10px; /* Added to separate buttons when they are next to each other */
  font-size: 1.2em; /* Increase font size for better readability */
}

.contact-form div {
  display: flex;
  justify-content: space-between; /* This will space buttons equally across the available space */
  margin-bottom: 20px;
}

.contact-text .large-text {
  font-size: 1.5em; /* Adjust this value as needed */
}

.image-side {
  flex: 1;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: start; /* Changed from center to start */
  overflow: hidden;
}

.image-container {
  margin-top: 25px;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: start; /* Changed from center to start */
}

.image-container img {
  height: 70vh;
  width: 50vw; /* Adjust width to maintain aspect ratio */
  object-fit: cover;
}

.contact-form button {
  background-color: #ee9c1a; /* Default color for all buttons except 'Next' */
  color: white;
  padding: 20px 30px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  /* margin: 20px 10px;  */
  font-size: 1.2em;
}

.contact-form div.button-container {
  display: flex;
  justify-content: left; /* Centers the buttons */
  align-items: left;
  flex-wrap: wrap; /* Allows buttons to wrap if space is insufficient */
}

.contact-form button.next {
  background-color: #27ae60; /* Green color for 'Next' button */
}

@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    align-items: stretch;
  }

  .image-side {
    height: auto;
    order: -1; /* Makes the image-side appear on top */
  }

  .image-container {
    height: 40vh;
    margin-top: 10px;
    align-items: end;
  }
  .image-container img {
    height: 80%; /* Making the image fill the container */
    width: 100%; /* Making the image fill the container */
    object-fit: cover;
  }

  .contact-form h2 {
    margin-block-start: 5px;
    margin-block-end: 5px;
  }

  .contact-form {
    width: 90%;
    padding: 15px;
  }
}
</style>

