<template>
  <div class="contact-container">
    <div class="image-side">
      <div class="image-container">
        <img src="../assets/goodTwo.jpg" alt="Living Room" />
      </div>
    </div>

    <div class="contact-form">
      <h2>Let's Get Started!</h2>
      <p>Fill out some basic information to get the party started!</p>
      <form @submit.prevent="submitForm">
        <div>
          <input
            type="text"
            v-model="form.firstName"
            placeholder="First name*"
            required
          />
          <input
            type="text"
            v-model="form.lastName"
            placeholder="Last name*"
            required
          />
          <input
            type="email"
            v-model="form.email"
            placeholder="Email*"
            required
          />
        </div>
        <button type="submit">Next</button>
      </form>
    </div>
  </div>
</template>


<script>
import { mapMutations } from "vuex";
import { collection, addDoc } from 'firebase/firestore';

export default {
  name: "ContactForm",
  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        email: "",
      },
    };
  },
  methods: {
    ...mapMutations(["UPDATE_USER_INFO"]),
    async submitForm() {
      try {
        // Save to Firebase
        await addDoc(collection(this.$db, "emails"), {
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          email: this.form.email
        });
        // console.log("Document written with ID: ", docRef.id);

        // Commit user info to the store
        this.UPDATE_USER_INFO(this.form);

        // Emit the next event to move to the next component
        this.$emit("next");
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    },
  },
};
</script>



<style scoped>
.contact-container {
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  gap: 20px;
  margin: auto;
  min-height: 500px;
}

.image-side {
  flex: 1;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: start;
  overflow: hidden;
  border-radius: 30px;
}

.image-container {
  margin-top: 25px;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: start;
  border-radius: 30px;
}

.image-container img {
  height: 70vh;
  width: 50vw;
  object-fit: cover;
  border-radius: 30px;
}

.contact-form {
  flex-basis: 50%;
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.contact-form form div {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.contact-form input,
.contact-form textarea {
  width: calc(100% - 20px);
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.contact-form button {
  background-color: #ee9c1a;
  color: white;
  padding: 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 18px;
}

@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    align-items: stretch;
  }

  .image-side {
    height: auto;
    order: -1; /* Makes the image-side appear on top */
  }

  .image-container {
    height: 40vh;
    margin-top: 10px;
    align-items: end;
  }
  .image-container img {
    height: 80%; /* Making the image fill the container */
    width: 100%; /* Making the image fill the container */
    object-fit: cover;
  }

  .contact-form h2 {
    margin-block-start: 5px;
    margin-block-end: 5px;
  }

  .contact-form {
    width: 90%;
    padding: 15px;
  }
}
</style>


